import React, { useRef, useState } from "react";
import "./Plugins.scss";
import plugin from "../../assets/images/plugin/plugin-image.png";
import pluginch from "../../assets/images/plugin/plugin-image-ch.png";
import pluginfr from "../../assets/images/plugin/plugin-image-fr.png";
import rhino from "../../assets/images/plugin/rhino.png";
import revit from "../../assets/images/plugin/revit.png";
import skechup from "../../assets/images/plugin/skechup.png";
import { useTranslation } from "react-i18next";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { AIRI_AWS_URL } from "../../utils/api-constant";

const Plugins = () => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const scrollRef = useRef(null);
  const lang = localStorage.getItem("language");
  const [activePlugin, setActivePLugin] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  const pluginsData = [
    {
      id: 1,
      heading: t("rhino_heading_main"),
      subHeading: t("rhino_heading"),
      content: t("rhino_content"),
      subHeading1: t("rhino_heading1"),
      content1: t("rhino_content1"),
      subHeading2: t("rhino_heading2"),
      content2: t("rhino_content2"),
      image: rhino,
      buttons: [
        {
          name: t("download_rhino1"),
          link: "https://airi-plugin.s3.cn-north-1.amazonaws.com.cn/AIRI+plugin+for+Rhino_6.zip",
        },
        {
          name: t("download_rhino2"),
          link: "https://airi-plugin.s3.cn-north-1.amazonaws.com.cn/AIRI+plugin+for+Rhino_7.zip",
        },
        {
          name: t("download_rhino3"),
          link: "https://airi-plugin.s3.cn-north-1.amazonaws.com.cn/AIRI+plugin+for+Rhino_8.zip",
        },
      ],
    },
    {
      id: 3,
      heading: t("revit_heading_main"),
      subHeading: t("revit_heading"),
      content: t("revit_content"),
      subHeading1: t("revit_heading1"),
      content1: t("revit_content1"),
      subHeading2: t("revit_heading2"),
      content2: t("revit_content2"),
      image: revit,
      buttons: [
        {
          name: t("download_revit1"),
        },
        {
          name: t("download_revit2"),
        },
        {
          name: t("download_revit3"),
        },
        {
          name: t("download_revit4"),
        },
        {
          name: t("download_revit5"),
        },
        {
          name: t("download_revit6"),
        },
      ],
    },
    {
      id: 2,
      heading: t("skechup_heading_main"),
      subHeading: t("skechup_heading"),
      content: t("skechup_content"),
      subHeading1: t("skechup_heading1"),
      content1: t("skechup_content1"),
      subHeading2: t("skechup_heading2"),
      content2: t("skechup_content2"),
      image: skechup,
      buttons: [
        {
          name: t("download_skechup1"),
        },
        {
          name: t("download_skechup2"),
        },
        {
          name: t("download_skechup3"),
        },
        {
          name: t("download_skechup4"),
        },
        {
          name: t("download_skechup5"),
        },
        {
          name: t("download_skechup6"),
        },
      ],
    },
  ];

  const handlePLugin = (id) => {
    setActivePLugin(id);
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDownload = (link) => {
    const anchor = document.createElement("a");
    anchor.href = link;
    anchor.download = "";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <UserPortalLayout>
      <div className="plugins">
        <div className="plugins__container">
          <div className="plugins__sections">
            <div className="plugins__banner">
              <div className="plugins__banner__left">
                <h2>{t("plugn_heading")}</h2>
                <p>{t("plugin_content")}</p>
                <div className="plugins__banner__left__buttons">
                  <button
                    onClick={() => handlePLugin(1)}
                    className={`${activePlugin === 1 ? "active" : ""}`}
                  >
                    {t("plugin_button1")}
                  </button>
                  {/* <button
                    onClick={() => handlePLugin(2)}
                    className={`${activePlugin === 2 ? "active" : ""}`}
                  >
                    {t("plugin_button2")}
                  </button>
                  <button
                    onClick={() => handlePLugin(3)}
                    className={`${activePlugin === 3 ? "active" : ""}`}
                  >
                    {t("plugin_button3")}
                  </button> */}
                </div>
              </div>
              <div className="plugins__banner__right">
                <img src={lang === "ch" ? pluginch : lang === "fr" ? pluginfr : plugin} alt="plugin" />
              </div>
            </div>
            <div className="" ref={scrollRef}>
              {pluginsData?.map((plugin, index) => {
                return (
                  <div
                    key={index}
                    className={`plugins__content ${
                      activePlugin === plugin?.id ? "active" : ""
                    }`}
                  >
                    <div className="plugins__content__left">
                      <div className="plugins__content__left__heading">
                        <img src={plugin?.image} alt="" />
                        <h2>{plugin?.heading}</h2>
                      </div>
                      <div className="plugins__content__left__content">
                        <h3>{plugin?.subHeading}</h3>
                        <p>{plugin?.content}</p>
                      </div>
                      <div className="plugins__content__left__content">
                        <h3>{plugin?.subHeading1}</h3>
                        <p>{plugin?.content1}</p>
                      </div>
                      <div className="plugins__content__left__content">
                        <h3>{plugin?.subHeading2}</h3>
                        <h6
                          onClick={() =>
                            handleDownload(
                              "https://airi-plugin.s3.cn-north-1.amazonaws.com.cn/AIRI+plugin+installation+tutorial.zip"
                            )
                          }
                        >
                          {t("install_link")}
                        </h6>
                      </div>
                      <div className="plugins__content__left__buttons">
                        {plugin?.buttons?.map((but, index) => {
                          return (
                            <button
                              key={index}
                              onClick={() => handleDownload(but?.link)}
                              className={`${
                                activePlugin === 1 ? "active" : ""
                              }`}
                            >
                              {but?.name}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="plugins__content__right">
                      <video
                        ref={videoRef}
                        style={{ display: isLoaded ? "block" : "none" }}
                        // controls
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="instruct__element"
                        onLoadedData={() => setIsLoaded(true)}
                      >
                        <source
                          src={`${AIRI_AWS_URL}/website/Rhino-plugin_C.mp4`}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default Plugins;
